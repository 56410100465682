import { Component, OnInit, AfterContentInit } from '@angular/core';
import { CmsserviceService } from '../../common-cms-service/cmsservice.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Title, Meta } from '@angular/platform-browser';
declare var jquery: any;
declare var $: any;
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, AfterContentInit {
  ngUnsubscribe = new Subject<void>();
  welcome: any;
  upcomingEventsrecord: any;
  images: any;
  banner: any;
  title = 'Sehyog';
  howithelp: any;
  siteSetting: any;
  constructor(
    private cmsService: CmsserviceService,
    private titleService: Title, private meta: Meta) {
      this.meta.addTags([ 
        { name: 'description', content: 'This is 11plus Examination portal' }, 
        { name: 'keywords', content: 'gcse revision, gcse maths, gcse past papers, gcse science, gcse maths past papers, gcse revision websites, science gcse online, english gcse revision, mathematics gcse, science and maths tutor, online maths test, english language gcse revision, edexcel gcse maths, 11 plus exam papers, 11 plus tuition, 11 plus tutor, 11 plus tutors near me, 11 plus mock exams, 11 plus mock test, 11 plus papers, 11 plus test papers, eleven plus tuition, eleven plus mock exams, eleven plus papers, eleven plus tutors, 11 plus exam tuition, eleven plus exam papers, 11 plus exam tutors, 11 plus exam mock test, eleven plus test papers, eleven plus mock test, eleven plus verbal reasoning' } 
    ]);
  }
  ngOnInit() {
    this.cmsService.commonSiteSettingSingleton().subscribe(res => {
      this.siteSetting = res;
    });
    $('.loader-bg').fadeIn();
    this.titleService.setTitle(this.title);
    this.welcomeToSehyog();
    this.upcomingTest();
    this.homeBanner();
    this.getTestCategoryList();
  }

  /** comment
   * welcome sehyog content home page
   */
  welcomeToSehyog() {
    this.cmsService.getCmsData('home')
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(res => {
        this.howithelp = res.data.fields[0].howItHelps;
        this.welcome = res.data.fields[0];
      });
  }

  /** comment
   * upcoming Session listing home page
   */
  upcomingTest() {
    this.cmsService.upcomingEventsRecord()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(res => {
        this.images = res.data;
      });
  }

  /** comment
   * home banner
   */
  homeBanner() {
    this.cmsService.homeBanner()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(res => {
        this.banner = res.data;
        $('.loader-bg').fadeOut();
      });
  }

  ngAfterContentInit(): void {
    $(document).ready(() => {
      $('.arrow-down').click(() => {
        const cls = $('.arrow-down').parent().attr('data-class');
        $('html, body').stop().animate({
          scrollTop: $('.' + cls).offset().top - 60
        }, 500);
      });

      $('.home-slider .slide').each(() =>  {
        $(this).css('background', 'url(' + $(this).find('img').attr('src') + ') no-repeat top center');
      });

    });
  }

  /** comment
   * get test category added on 02-03-2024
   */
  categoryListing: any = [];
  getTestCategoryList() {
    this.cmsService.getTestCategories()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(res => {
        this.categoryListing = res.data;
      });
  }
}
