/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./front-layout.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./home/home.component.ngfactory";
import * as i3 from "./home/home.component";
import * as i4 from "../common-cms-service/cmsservice.service";
import * as i5 from "@angular/platform-browser";
import * as i6 from "./common/header/header.component.ngfactory";
import * as i7 from "./common/header/header.component";
import * as i8 from "@angular/router";
import * as i9 from "../common-myaccount-service/common-account-verify.service";
import * as i10 from "ngx-cookie-service";
import * as i11 from "@angular/common";
import * as i12 from "./common/footer/footer.component.ngfactory";
import * as i13 from "./common/footer/footer.component";
import * as i14 from "@angular/forms";
import * as i15 from "./front-layout.component";
var styles_FrontLayoutComponent = [i0.styles];
var RenderType_FrontLayoutComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FrontLayoutComponent, data: {} });
export { RenderType_FrontLayoutComponent as RenderType_FrontLayoutComponent };
function View_FrontLayoutComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-home", [], null, null, null, i2.View_HomeComponent_0, i2.RenderType_HomeComponent)), i1.ɵdid(1, 1163264, null, 0, i3.HomeComponent, [i4.CmsserviceService, i5.Title, i5.Meta], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_FrontLayoutComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-home", [], null, null, null, i2.View_HomeComponent_0, i2.RenderType_HomeComponent)), i1.ɵdid(1, 1163264, null, 0, i3.HomeComponent, [i4.CmsserviceService, i5.Title, i5.Meta], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_FrontLayoutComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-header", [], null, null, null, i6.View_HeaderComponent_0, i6.RenderType_HeaderComponent)), i1.ɵdid(1, 1294336, null, 0, i7.HeaderComponent, [i4.CmsserviceService, i5.Title, i8.Router, i8.ActivatedRoute, i9.CommonAccountVerifyService, i10.CookieService], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FrontLayoutComponent_1)), i1.ɵdid(3, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FrontLayoutComponent_2)), i1.ɵdid(5, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(7, 212992, null, 0, i8.RouterOutlet, [i8.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(8, 0, null, null, 1, "app-footer", [], null, null, null, i12.View_FooterComponent_0, i12.RenderType_FooterComponent)), i1.ɵdid(9, 1294336, null, 0, i13.FooterComponent, [i4.CmsserviceService, i8.Router, i10.CookieService, i14.FormBuilder], null, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_0 = (_co.router.url == "/"); _ck(_v, 3, 0, currVal_0); var currVal_1 = (_co.router.url == "/UAE"); _ck(_v, 5, 0, currVal_1); _ck(_v, 7, 0); _ck(_v, 9, 0); }, null); }
export function View_FrontLayoutComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-front-layout", [], null, null, null, View_FrontLayoutComponent_0, RenderType_FrontLayoutComponent)), i1.ɵdid(1, 114688, null, 0, i15.FrontLayoutComponent, [i8.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FrontLayoutComponentNgFactory = i1.ɵccf("app-front-layout", i15.FrontLayoutComponent, View_FrontLayoutComponent_Host_0, {}, {}, []);
export { FrontLayoutComponentNgFactory as FrontLayoutComponentNgFactory };
