import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FrontLayoutComponent } from './front-layout.component';
import { UpcomingEventsComponent } from './upcoming-events/upcoming-events.component';
import { AcademyComponent } from './academy/academy.component';

const routes: Routes = [
  {
    path: '', component: FrontLayoutComponent,
    children: [
      { path: 'sehyog', loadChildren: '../front-layout/sehyog/sehyog.module#SehyogModule' },
      { path: '11plus-help-club', loadChildren: '../front-layout/eleven-plus/eleven-plus.module#ElevenPlusModule' },
      { path: 'become-a-volunteer', loadChildren: '../front-layout/become-volunteer/become-volunteer.module#BecomeVolunteerModule' },
      { path: 'donation', loadChildren: '../front-layout/make-donation/make-donation.module#MakeDonationModule' },
      { path: 'freedemo', loadChildren: '../front-layout/make-donation/make-donation.module#MakeDonationModule' },
      { path: 'request-for-franchise', loadChildren: '../front-layout/request-franchise/request-franchise.module#RequestFranchiseModule' },
      { path: 'contact-us', loadChildren: '../front-layout/contact-us/contact-us.module#ContactUsModule' },
      { path: 'basket', loadChildren: '../front-layout/basket/basket.module#BasketModule'},
      { path: 'payment', loadChildren: '../front-layout/payment/payment.module#PaymentModule'},
      { path: 'support', loadChildren: '../front-layout/support/support.module#SupportModule' },
      { path: 'legal', loadChildren: '../front-layout/legal/legal.module#LegalModule' },
      { path: '11plus-live' , component: UpcomingEventsComponent },
      { path: 'academy' , component: AcademyComponent },
    ]
  },
  { path: 'UAE', component: FrontLayoutComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FrontLayoutRoutingModule { }
